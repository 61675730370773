<template>
  <div class="container">
    <div class="title">消费列表</div>
    <div class="content">
      <div class="toolbar_box">
        <el-form
          ref="form"
          :model="tableSearchData"
          label-width="80px"
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="tableSearchData.order_num"
              placeholder="订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品编号">
            <el-input
              v-model="tableSearchData.goods_id"
              placeholder="商品编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="充值状态">
            <el-select
              v-model="tableSearchData.status"
              placeholder="请选择充值状态"
              clearable
            >
              <el-option
                label="待充值"
                :value="0"
              ></el-option>
              <el-option
                label="充值中"
                :value="1"
              ></el-option>
              <el-option
                label="充值成功"
                :value="2"
              ></el-option>
              <el-option
                label="充值待确认"
                :value="3"
              ></el-option>
              <el-option
                label="充值失败"
                :value="4"
              ></el-option>
              <el-option
                label="退款"
                :value="5"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间">
            <el-date-picker
              v-model="tableSearchData.start_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleResetSearch(getTableData)">重置
            </el-button>
            <el-button
              type="primary"
              @click="handleTableSearch(getTableData)"
            >查询</el-button>
          </el-form-item>

        </el-form>
      </div>
      <div class="tabel_box">
        <el-table
          :data="tableData"
          stripe
          height="100%"
          v-loading="loading"
        >
          <el-table-column
            prop="goods_name"
            label="商品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="goods_id"
            label="商品编号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="order_num"
            label="订单编号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="actual_fee"
            label="实付金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="actual_fee"
            label="充值状态"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status==0">待充值</span>
              <span v-else-if="scope.row.status==1">充值中</span>
              <span v-else-if="scope.row.status==2">充值成功</span>
              <span v-else-if="scope.row.status==3">充值待确认</span>
              <span v-else-if="scope.row.status==4">充值失败</span>
              <span v-else-if="scope.row.status==5">退款</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
          background
          layout=" sizes, prev, pager, next, jumper"
          :page-count="pageTotal"
          :current-page="pageNum"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @size-change="
            (val) => {
              handleSizeChange(getTableData, val);
            }
          "
          @current-change="
            (val) => {
              handleCurrentChange(getTableData, val);
            }
          "
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsumeOrderListApi } from '../../../api/cost'
import { paginationMixins } from '../../../mixins/paginationMixins'
export default {
  // 组件名称
  name: '',
  mixins: [paginationMixins],
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      loading: false,
      tableSearchData: {
        order_num: '',
        goods_id: '',
        status: '',
        start_time: ''
      },
      tableData: []
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.getTableData()
  },
  mounted () {

  },
  // 组件方法
  methods: {
    async getTableData () {
      this.loading = true

      const res = await getConsumeOrderListApi({
        ...this.tableSearchData,
        page: this.pageNum,
        limit: this.pageSize
      })
      this.loading = false

      // console.log(res)
      if (res && res.code === 0) {
        this.tableData = res.data.data
        this.pageTotal = res.data.total_page
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    // display: flex;
    height: calc(100% - 35px);
    box-sizing: border-box;
    .toolbar_box {
      height: 130px;
      padding-top: 15px;
      // background-color: pink;
      box-sizing: border-box;
      overflow: hidden;
      .el-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item {
          width: 28%;
          margin-left: 20px;
        }
        .el-select {
          width: 100%;
        }
        /deep/.el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
      }
    }

    .tabel_box {
      height: calc(100% - 180px);
    }
    .pagination-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      // background-color: pink;
    }
  }
}
</style>
