import request from './request'

// 注册

export function getChargeOrderListApi (data) {
  return request({
    url: '/client/order/chargeOrderList',
    method: 'POST',
    data
  })
}

export function getConsumeOrderListApi (data) {
  return request({
    url: '/client/order/consumeOrderList',
    method: 'POST',
    data
  })
}

export function chargeMobileOrderList (data) {
  return request({
    url: '/client/order/chargeMobileOrderList',
    method: 'POST',
    data
  })
}
